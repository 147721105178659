import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PerformanceStylesCSS } from './Performance.styled';
import { ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, AdminDashboards } from '../../../variables/constant';
import { DashboardContext, DashboardContextInit, DashboardProps } from '../admin-analytics-hook';
import InsufficientDataBackdrop from '../InsufficientDataBackdrop';
import OverallPerformanceWidget from './OverallPerformanceWidget';
import PerformanceWidget from './PerformanceWidget';
import TasksAssignedAndCompletedWidget from './TasksAssignedAndCompletedWidget';
import StudentCompletionRateWidget from './StudentCompletionRateWidget';
import Loader from '../../../components/Loader/loader';
import { toReadableFormat } from '../../../utils/dateFormat';
import { useEvaluateFlagQuery } from '../../../generated/graphql';
import { AssignmentStatusReportWidget } from './AssignmentStatusReportWidget';
import { OverallIndicatorScoresWidget } from './OverallIndicatorScoresWidget';
import { OverallByMonthWidget } from './OverallByMonthWidget';
import AnalyticsFilters from '../AnalyticsFilters';

export const AssignmentData = ({
  isDistrictAdmin,
  myDistrictDataLoading,
  myDistrictData,
  isSchoolAdmin,
  mySchoolDataLoading,
  mySchoolData,
  academicSessionLoading,
  academicSession,
  lastSnowflakeUpdateTimeData,
  getLastSnowflakeUpdateTimeLoading,
}: DashboardProps) => {
  const theme = useTheme();
  const styles = PerformanceStylesCSS(theme);

  const adminPerformanceDataPhase3Default = false;
  const { data: adminPerformanceDataPhase3, loading: isFeatureFlagLoading } = useEvaluateFlagQuery({
    fetchPolicy: 'network-only',
    variables: { input: { defaultValue: adminPerformanceDataPhase3Default, flagKey: 'admin-perf-data' } },
  });
  const showAdminPerformanceDataPhase3 =
    adminPerformanceDataPhase3?.evaluateFlag.on ?? adminPerformanceDataPhase3Default;

  function determineVisibleWidgets(showAdminPerformanceDataPhase3: boolean, allAssignmentDataWidgets: any) {
    if (showAdminPerformanceDataPhase3) {
      const { overallByMonth, assignmentCompletionRatesWidget, PerformanceSliderWidget, overallIndicatorScoresWidget } =
        allAssignmentDataWidgets;
      return {
        overallByMonth,
        assignmentCompletionRatesWidget,
        PerformanceSliderWidget,
        overallIndicatorScoresWidget,
      };
    } else {
      const {
        overallPerformance,
        studentCompletionRatesWidget,
        PerformanceSliderWidget,
        TasksAssignedNCompletedWidget,
      } = allAssignmentDataWidgets;
      return {
        overallPerformance,
        studentCompletionRatesWidget,
        PerformanceSliderWidget,
        TasksAssignedNCompletedWidget,
      };
    }
  }

  // TODO: We can remove this useEffect when we clean up admin-perf-data feature flag.
  useEffect(() => {
    setDashboardWidgetsDataStatus(
      Object.values(determineVisibleWidgets(showAdminPerformanceDataPhase3, AdminDashboards.performance)).map(
        (widgetData: any) => ({
          widget: widgetData.name,
          loading: true,
          dataMissing: false,
        })
      )
    );
  }, [showAdminPerformanceDataPhase3]);

  const {
    loading,
    setLoading,
    getLocalStorageDashboardFilters,
    setLocalStorageDashboardFilters,
    localDashboardFilters,
    dashboardFilters,
    currentFilters,
    setCurrentFilters,
    analyticsFilters,
    setAnalyticsFilters,
    startDateInit,
    startDateSelected,
    setStartDateSelected,
    endDateSelected,
    setEndDateSelected,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    areWidgetsLoading,
    setAreWidgetsLoading,
  } = DashboardContextInit({
    dashboardWidgets: determineVisibleWidgets(showAdminPerformanceDataPhase3, AdminDashboards.performance),
    isDistrictAdmin,
    myDistrictDataLoading,
    myDistrictData,
    isSchoolAdmin,
    mySchoolDataLoading,
    mySchoolData,
    academicSessionLoading,
    academicSession,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  });
  return (
    <DashboardContext.Provider
      value={{
        loading,
        setLoading,
        isSchoolAdmin,
        mySchoolData,
        isDistrictAdmin,
        myDistrictData,
        dashboardFilters,
        lastSnowflakeUpdateTimeData,
        getLastSnowflakeUpdateTimeLoading,
        currentFilters,
        setCurrentFilters,
        analyticsFilters,
        setAnalyticsFilters,
        academicSessionLoading,
        academicSession,
        startDateInit,
        startDateSelected,
        setStartDateSelected,
        endDateSelected,
        setEndDateSelected,
        dashboardWidgetsDataStatus,
        setDashboardWidgetsDataStatus,
        isDataMissingInAllWidgets,
        setIsDataMissingInAllWidgets,
        areWidgetsLoading,
        setAreWidgetsLoading,
        getLocalStorageDashboardFilters,
        setLocalStorageDashboardFilters,
        localDashboardFilters,
      }}
    >
      <Loader
        open={
          loading || isFeatureFlagLoading || !academicSession || !analyticsFilters || getLastSnowflakeUpdateTimeLoading
        }
      />
      <Box display="flex" flexDirection="column" width="100%" sx={styles.dashBoardContainer} id="performance-dashboard">
        <Box sx={{ marginBottom: 2 }} id="performance-dashboard-header">
          <Typography variant="pageTitle">Assignment Data Dashboard</Typography>
          <Box>
            {!!lastSnowflakeUpdateTimeData && (
              <Typography variant="toolTipGreyText">
                Last Updated:{' '}
                {toReadableFormat(
                  lastSnowflakeUpdateTimeData,
                  ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR,
                  'en-US'
                ).replace('at', '')}{' '}
                UTC
              </Typography>
            )}
          </Box>
        </Box>
        {!!academicSession && !!analyticsFilters && (
          <Box>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} p={2}>
              <AnalyticsFilters disablePdf={true} disableCustomDate={true} />
            </Grid>
            <Grid container spacing={2} sx={{ position: 'relative' }}>
              <InsufficientDataBackdrop dataMissing={!loading! && isDataMissingInAllWidgets!} />
              {!isFeatureFlagLoading && showAdminPerformanceDataPhase3 && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OverallByMonthWidget />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <PerformanceWidget
                      enableTooltip
                      alignTitle
                      tooltipTitle={AdminDashboards.performance.PerformanceSliderWidget.tooltipText}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <OverallIndicatorScoresWidget />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AssignmentStatusReportWidget />
                  </Grid>
                </>
              )}
              {!isFeatureFlagLoading && !showAdminPerformanceDataPhase3 && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OverallPerformanceWidget />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <StudentCompletionRateWidget />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <PerformanceWidget
                      enableTooltip
                      alignTitle
                      tooltipTitle={AdminDashboards.performance.PerformanceSliderWidget.tooltipText}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TasksAssignedAndCompletedWidget />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </DashboardContext.Provider>
  );
};
